import bevmenu from "../bev.png"
export default function BeverageMenu() {
    return (
        <>
    <div class="py-40 text-center ">
    <h2 id="rust" className="mt-14 lg:mt-28 md:text-7xl tracking-tight text-gray-900 text-5xl">Beverage Menu</h2>
        <img
              src={bevmenu}
              alt="Photo of Jason A. Vickers"
              className="object-contain lg:w-1/2 md:px-8 justify-center m-auto mt-14 px-8 "
            />     
        
        
        

            <div className="mt-12 -mb-28  md:grid md:grid-cols-3 -gap-4	grid sm:grid-none    ">
            <a
              href="/SampleWinterMenu"
              className="rounded-md m-4 mx-auto md:mx-auto bg-blue px-3.5 py-2.5 text-m font-bold text-white shadow-sm hover:bg-black transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Sample Winter Menu
            </a>

            <a
              href="/Catering" target="_blank"
              className="rounded-md m-4 mx-auto px-3.5 py-2.5 text-m text-black hover:text-red lg: transition duration-700 hover:text-red focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Back to catering request
            </a>
            <a
              href="/AppetizerMenu"
              className="rounded-md m-4 mx-auto md:mx-auto bg-red px-3.5 py-2.5 text-m font-bold text-white shadow-sm hover:bg-black transition duration-700 hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Appetizer Menu
            </a>
           
          </div>
        </div>
        
        
        </>






    )
}