import MealPrepHero from "./MealPrepHero"
import mp from "../mp1.png"
import mp2 from "../mp2.jpeg"






//4d6eee58-b87b-4a0d-b252-bc6038999dfc
export default function MealPrep () {

  const faqs = [
    {
      id: 1,
      question: "When do you deliver?",
      answer:
        "Mondays in the early half of the day.",
    },
    {
      id: 2,
      question: "Where do you deliver?",
      answer:
        "Greater Seattle Area; generally Renton to Edmonds, West Seattle to Bellevue depending on where your location is, if a delivery fee is needed, we will reach out (Kirkland and Redmond, we can talk.",
    },
    {
      id: 3,
      question: "Do you deliver outside of Seattle?",
      answer:
        "It can be considered based on distance, and if it works out $10-$15 delivery fee will be applied.",
    },
    {
      id: 4,
      question: "When is the order deadline?",
      answer:
        "Order deadline for Monday delivery is Saturday at 12 PM.",
    },
    {
      id: 5,
      question: "What are the accepted forms of payment?",
      answer:
        "Credit card via square is primary; for special circumstances we can consider venmo, cashapp, cash, check. Send an email to jason@natoncksmetsu.com",
    },
    {
      id: 6,
      question: "Are the meals gluten free or dairy free?",
      answer:
        "YES, always.",
    },
    {
      id: 7,
      question: "Are there options for vegans and vegetarians?",
      answer:
        "Meals can be prepared without meat with substitution of tofu, lentils, other high protein items and more veggie portions.",
    },
    {
      id: 8,
      question: "Will they need to be stored?",
      answer:
        "Meals will be delivered cold, refrigerate immediately.",
    },
    {
      id: 9,
      question: "Will they need to be reheated?",
      answer:
        "Meals are enjoyed best when reheated, microwave, oven or skillet.",
    }
  ]


    return (

        <>

        

      <div className="relative bg-white">
              <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-0  md:px-3  lg:mb-20  ">
                <div className="px-6 pb-24 sm:pb-20  pt-10 sm:pt-20 sm:pb-32 lg:col-span-7  md:px-0  lg:pt-60 xl:col-span-5">
                  <div className="mx-auto max-w-2xl lg:mx-0 ">
                  
                    <div className="sm:mt-32 mt-32 mb-6  sm:flex lg:mt-20  ">
                      <div className="relative rounded-full lg:px-3 px-4 py-2  italic ring-none text-red text-sm leading-6 text-gray-500 font-bold  md:ring-2 md:ring-red ">
                        Available for Home Delivery!{' '}
                        
                      </div>
                    </div>



                    
                    <h1 id="rust" className=" text-4xl rust tracking-tight animation-bounce text-gray-900 sm:mt-10 sm:text-6xl">
                      Meal Prep
                    </h1>
                    <p className="mt-6 lg:text-lg leading-8 lg:leading-7 text-gray-600 sm:text-md text-sm ">
                    Natoncks Metsu (Feeding My Cousins) Weekly Meal Prep is a weekly batch of handmade meals that are high in protein, packed with fresh seasonal vegetables and balanced with a carefully managed carbohydrate content. Chef will always work to incorporate traditional foods into meal prep. Items may be hand gathered, tribal caught, tribal harvested.
                    </p>
                    <div className="mt-10 flex items-center gap-x-6">
                      <a
                        href="#placeorder"
                        className="rounded-md bg-beige hover:bg-brown hover:text-white transition duration-700 bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-black shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      >
                      Place order
                      </a>
                      <a href="#faq" className="text-sm  font-semibold hover:text-red leading-6 text-gray-900">
                        FAQ <span aria-hidden="true">→</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="relative lg:col-span-5 lg:-mr-10 xl:absolute xl:inset-0 xl:left-1/2    xl:mr-0">
                  <img
                    className="aspect-[3/2] w-full  bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
                    src={mp}
                    alt=""
                  />
                </div>


                
              </div>
            </div>



          <div>







          <div class="text-center items-center justify-center m-auto p-0 mt-14 lg:mt-0  flex">
          <span class="animate-bounce"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class=" mx-6 w-8 h-8  text-red items center ">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
        </svg></span>

        <span class="animate-bounce delay-200 "><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className=" w-12 h-12 text-red  ">
          <path fillRule="evenodd" d="M12.963 2.286a.75.75 0 00-1.071-.136 9.742 9.742 0 00-3.539 6.177A7.547 7.547 0 016.648 6.61a.75.75 0 00-1.152-.082A9 9 0 1015.68 4.534a7.46 7.46 0 01-2.717-2.248zM15.75 14.25a3.75 3.75 0 11-7.313-1.172c.628.465 1.35.81 2.133 1a5.99 5.99 0 011.925-3.545 3.75 3.75 0 013.255 3.717z" clipRule="evenodd" />
        </svg>
        </span>

        <span class="animate-bounce"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class=" mx-6 w-8 h-8 m-auto text-red items center ">
          <path stroke-linecap="round" stroke-linejoin="round" d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z" />
          <path stroke-linecap="round" stroke-linejoin="round" d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z" />
        </svg></span>

        </div>


            
             



          <div className="mx-auto lg:mt-30 mb-30  max-w-7xl px-6 mt- lg:px-8 ">
          <div className="mx-auto flex max-w-2xl flex-col items-end justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row">
            <div className="w-full lg:max-w-lg lg:flex-auto lg:order-first order-last">
              
              
              
              <img
                src={mp2}
                alt=""
                className="mt-5 lg:mt-20 aspect-[6/5] w-full  rounded-2xl bg-gray-50 object-cover lg:aspect-auto lg:h-[34.5rem]"
              />
            </div> 
            <div className="w-full lg:max-w-xl lg:flex-auto md:mb-16 px-4 ">              
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:mb-6 mb-6 lg:mt-0 mt-12">
              Our meals <span class="text-red">are:</span></h2>
              <ul class="list-disc px-6 leading-6 vertical-align">
              <li class="py-3">All meal prep meals are GLUTEN FREE & DAIRY FREE</li>
              
              <li class="py-3">Low carbohydrate meals</li>
              <li class="py-3">We use fresh natural proteins</li>
              <li class="py-3">Include generous veggie portions</li>
              <li class="py-3">Each meal is a 16 oz portion; enough for one substantial eater or 1.5 meals for a lighter eater </li>
              <li class="py-3">Great for lunch or dinner!</li>
            </ul>
                </div>
          </div>
</div>



          </div>







       

       

        <div id="placeorder" class="mt-6 lg:py-2  mx-auto py-12 sm:py-0 max-w-2xl text-center bg">
          <h2 id="rust-underline" className="mt-6 sm:mt-20 text-4xl tracking-tight text-gray-900 sm:text-4xl text-center ">How to order</h2>
          <p className="mt-6 text-center px-8 leading-6 ">
          Order deadline for your Monday delivery is Saturday at 12 pm Weekly Menus are posted on Wednesdays for the following week: you can find them on social media and your email inbox (if you want to opt out, just let us know.)</p>

        </div>


        

        <div id="mp-links" className="mx-auto max-w-2xl lg:mt-14 sm:mt-10 text-center">
          <a href="https://square.link/u/nOZZYWqF" target="_blank"><button id="button">Weekly Meal Prep Subscription</button></a>
          <a href="https://square.link/u/oT4ysiTC" target="_blank"><button id="button">One Week Meal Prep Order</button></a>
          <a href="https://square.link/u/v08JdV8m" target="_blank"><button id="button">4-Pack Regular</button></a>
          <a href="https://square.link/u/duTsNz61" target="_blank"><button id="button">Vegatarian 5-Pack</button></a>



        </div>

        <div class="mx-auto max-w-2xl text-center">
          <p className="mt-10 text-center px-8 ">
          Natoncks Metsu Weekly Meal Prep is designed for anyone who wants to improve the way they eat, but may not have the time to prepare a week’s worth of meals. We know you want to select healthy foods and we are making it convenient for you!<br></br><br></br>
          Also, if you are serious about exercise, gym routines, yoga, crossfit, MMA, or you are an amateur or professional athlete, Chef Jason, has years of experience meal prepping for athletes and teams: Seattle Seahawks, UW Huskies Football! <br></br><br></br>
          We are cooking for you, cousin.
        </p>
        </div>
      

      <div id="faq" className="bg-white py-2">
            <div className="mx-auto max-w-7xl divide-y divide-gray-900/10  px-6 py-12 lg:px-8 lg:py-28">
              <h2 id="rust" className="text-2xl leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
              <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
                {faqs.map((faq) => (
                  <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
                    <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-5">{faq.question}</dt>
                    <dd className="mt-4 lg:col-span-7 lg:mt-0">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>


</>
    )
}
 


/*

<div id="mpheader" className="bg-white px-6 py-24 sm:py-32 lg:px-8" >
          <div className="mx-auto max-w-2xl text-center">

            
            <h2 id="rust" className="mt-6 text-4xl tracking-tight text-gray-900 sm:text-6xl">Meal Prep Inquiry</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Curated Monthly Menu for Handmade Meal Prep<br></br>
            Available for Weekly Home Delivery!</p>
            <h2 class="mt-6">Natoncks Metsu (Feeding My Cousins) Weekly Meal Prep is a weekly batch of handmade meals that are high in protein, packed with fresh seasonal vegetables and balanced with a carefully managed carbohydrate content. Chef will always work to incorporate traditional foods into meal prep. Items may be hand gathered, tribal caught, tribal harvested. </h2>

          </div>
        </div>

        */