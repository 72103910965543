import './App.css';
import Navigation from './components/Navigation';
import Home from './components/Home'
import About from './components/About';
import Catering from './components/Catering'
import Footer from './components/Footer'
import Contact from './components/Contact'
import MealPrep from './components/MealPrep'
import Press from './components/Press'


import { BrowserRouter as Router, Routes, Route}
    from 'react-router-dom';
import AppetizerMenu from './components/AppetizerMenu'
import BeverageMenu from './components/BevMenu.js'
import WinterMenu from './components/WinterMenu.js'
import SummerMenu from './components/SummerMenu.js'



function App() {
  return (
      <Router>
      <Navigation />

      <Routes>

      <Route exact path='/' element={<Home />} />
      <Route exact path='/About' element={<About />} />
      <Route exact path='/Catering' element={<Catering />} />
      <Route exact path='/Meal-Prep' element={<MealPrep />} />
      <Route exact path='/Events&Press' element={<Press />} />

      <Route exact path='/Contact' element={<Contact />} />
      <Route exact path='/BeverageMenu' element={<BeverageMenu />} />

      <Route exact path='/SummerMenu' element={<SummerMenu />} />

      <Route exact path='/AppetizerMenu' element={<AppetizerMenu />} />






      </Routes>
      <Footer />

      </Router>

  );
}

export default App;
