import circle from "../circle.png"


export default function EventsBanner() {
    return(
            <>
            
            <div className="bg-red">


                <div className="mx-auto max-w-7xl px-10 py-16 sm:py-12  text-center sm:text-center lg:flex lg:items-center lg:justify-between items-center lg:px-20">
                    <h2 className="text-2xl font-bold tracking-tight text-white sm:text-4xl sm:text-center">
                        See all Natonks Metsu events
                    </h2>
                    <div className="mt-10  items-center  lg:mt-0 inline-flex lg:flex-shrink-0">
                        <a
                            href="/Events&Press" target="_blank"
                            className="rounded-md shadow-2xl px-3.5 py-2 text-m font-bold  text-white bold m-2 p-10 text-white transition-all duration-500 hover:border-2 hover:text-black border-2 border-beige hover:bg-beige"
                        >
                            Go to Events<span aria-hidden="true" className="px-2">→</span>
                        </a>
                    </div>
                </div>
                </div>
            
            
            </>
    )
}