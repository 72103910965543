import React from "react";
import nm1 from '../nm1.png'
import Footer from './Footer'

//4d6eee58-b87b-4a0d-b252-bc6038999dfc


export default function Contact() {


    return (
        <>
    <div id="contactheader" className="bg-white px-6 py-24  lg:px-8 py-24 sm:py-32" >
      <div className=" mx-auto max-w-2xl text-center">
        <h2 id="rust" className="mt-6 text-4xl tracking-tight text-gray-900 sm:text-6xl">Contact</h2>
        <p className="mt-6 mb-6 text-lg leading-8 text-gray-600">
        <a href="tel:+1 206-939-7055" className="hover:text-red">+1 206-939-7055</a><br></br> 
        <a href="mailto:jason@natoncksmetsu.com" className="hover:text-red" target="_blank" rel="noreferrer">hello@natoncksmetsu.com</a>

        </p>
      </div>
    </div>

<div  class="flex items-center   bg-gray-100 dark:bg-gray-900 tracking-tight">
  <div class="container mx-auto">
    <div class="max-w-xl mx-auto  bg-white p-5 rounded-md shadow-sm">
      <div class="text-center">

        <p class="  text-gray-400 dark:text-gray-400">
        Fill out the form below to send us a general message.<br></br>We look forward to speaking with you!
        </p>
      </div>
      <div class="m-7">
        <form action="https://api.web3forms.com/submit" method="POST" id="form" class="needs-validation" target="_blank" novalidate>
          <input type="hidden" name="access_key" value="4d6eee58-b87b-4a0d-b252-bc6038999dfc" />
          <input type="hidden" name="subject" value="Contact Request www.natoncksmetsu.com" />

          <div class="flex mb-6 space-x-4">
            <div class="w-full md:w-1/2">
              <label for="fname" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">First Name</label>
              <input type="text" name="name" id="first_name" placeholder="John" required class="w-full px-3 py-2 placeholder-gray-300 border-2 border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-beige focus:border-beige" />
              <div class="empty-feedback invalid-feedback text-red-400 text-sm mt-1">
                Please provide your first name.
              </div>
            </div>
            <div class="w-full md:w-1/2">
              <label for="lname" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Last Name</label>
              <input type="text" name="last_name" id="lname" placeholder="Doe" required class="w-full px-3 py-2 placeholder-gray-300 border-2 border-gray-200 rounded-md focus:outline-none focus:ring focus:ring-beige focus:border-beige" />
              <div class="empty-feedback invalid-feedback text-brown text-sm mt-1">
                Please provide your last name.
              </div>
            </div>
          </div>

          <div class="flex mb-6 space-x-4">
            <div class="w-full md:w-1/2">
              <label for="email" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Email Address</label>
              <input type="email" name="email" id="email" placeholder="you@company.com" required class="w-full px-3 py-2 placeholder-gray-300 border-2 border-gray-200 rounded-md focus:outline-none focus:ring focus:ring-beige focus:border-beige" />
              <div class="empty-feedback text-red-400 text-sm mt-1">
                Please provide your email address.
              </div>
              <div class="invalid-feedback text-red-400 text-sm mt-1">
                Please provide a valid email address.
              </div>
            </div>

            <div class="w-full md:w-1/2">
              <label for="phone" class="block text-sm mb-2 text-gray-600 dark:text-gray-400">Phone Number</label>
              <input type="text" name="phone" id="phone" placeholder="+1 (555) 123-4567" required class="w-full px-3 py-2 placeholder-gray-300 border-2 border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-beige focus:border-beige" />

              <div class="empty-feedback invalid-feedback text-red-400 text-sm mt-1">
                Please provide your phone number.
              </div>
            </div>
          </div>
          <div class="mb-6">
            <label for="message" class="block mb-2 text-sm text-gray-600 dark:text-gray-400">Your Message</label>

            <textarea rows="5" name="message" id="message" placeholder="Your Message" class="w-full px-3 py-2 placeholder-gray-300 border-2 border-gray-200 rounded-md focus:outline-none focus:ring focus:ring-beige focus:border-beige" required></textarea>
            <div class="empty-feedback invalid-feedback text-red-400 text-sm mt-1">
              Please enter your message.
            </div>
          </div>
          <div class="">
            <button id="btn" type="submit" class="w-full px-3 py-4 text-white bg-beige rounded-md focus:bg-indigo-600 focus:outline-none">
              Send Message
            </button>
          </div>
          <p class="text-base text-center text-gray-400" id="result"></p>
        </form>
      </div>
    </div>
  </div>
</div>
        </>
    )
}