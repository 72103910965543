import React from "react";
import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import nmheader2 from '../nmheader2.png'
import Banner from './Banner'



export default function Navigation() {

const navigation = [
    { name: 'Home', href: '/' },
    { name: 'About', href: '/About' },
    { name: 'Catering', href: '/Catering' },
    { name: 'Meal Prep', href: '/Meal-Prep' },
    { name: 'Events & Press', href: '/Events&Press' },
    { name: 'Contact', href: '/Contact' },
  ]

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)


  return(
    <header className="absolute inset-x-0 top-0 z-50">
    <nav className="flex items-center justify-between p-1 lg:px-8" aria-label="Global">
      <div className="flex lg:flex-1">
        <a href="/" className="-m-1.5 p-0">
          <span className="sr-only">Natoncks Metsu</span>
          <img
            className="h-32 w-auto"
            src={nmheader2}
            alt="Natoncks Metsu Logo"
          />
        </a>
      </div>
      <div className="flex lg:hidden">
        <button
          type="button"
          className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open main menu</span>
          <Bars3Icon className="h-6 w-6 mr-3" aria-hidden="true" />
        </button>
      </div>
      <div className="hidden lg:flex lg:gap-x-12 mr-10">
        {navigation.map((item) => (
          <a key={item.name} href={item.href} className="text-sm font-semibold leading-6 text-black">
            {item.name}
          </a>
        ))}
      </div>

      
    </nav>
    <Banner />

 
    <Dialog as="div" id="mobile" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
      <div className="fixed inset-0 z-50" id="mobile" />
      <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-900 px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
        <div className="flex items-center justify-between content-center">
          <a href="/" className="-m-1.5 p-1.5 outline-none">
            <span className="sr-only">Natoncks Metsu</span>
            <img
            className=" w-auto"
            src={nmheader2}
            id="mobilelogo"
            alt="Natoncks Metsu logo"
          />
          </a>
          <button
            type="button"
            className="-m-2.5 rounded-md p-2.5 text-gray-400"
            onClick={() => setMobileMenuOpen(false)}
          >
            <span className="sr-only">Close menu</span>
            <XMarkIcon id="iconicon" className="h-6 w-6" aria-hidden="true" />
          </button>

        </div>
        <div id="mobilebanner" className="px-0 py-0 -ml-6">
        <Banner />
        </div>

        <div className="mt-6 flow-root text-center">
          <div className="-my-6 divide-y divide-gray-500/25">
            <div className="space-y-2 py-6">
              {navigation.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  id="black"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base text-red  font-semibold leading-7 hover:bg-gray-800"
                >
                  {item.name}
                </a>

                
                
              ))}

              
            </div>
            

          </div>
        </div>

        
      </Dialog.Panel>
    </Dialog>

    
  </header>
  )
}