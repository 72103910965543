import React from "react";
import stripe from '../stripe.png'


export default function Banner(){
    return (
        <div>
        <img
        src={stripe}
        alt=""
        id='stripe'
        className=" object-cover h-20"
      />
      </div>
    )
}