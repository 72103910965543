import React from "react";
import jason from '../jason.jpeg'



export default function About() {

    return(
        <>
        <div className="-mb-32 relative isolate -z-10 overflow-hidden bg-gradient-to-b from-indigo-100/20 mt-20">

        <div className="mx-auto max-w-7xl px-6 py-32 sm:py-40 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8 px-4">
            <h1 id="aboutheader" className="max-w-2xl text-4xl tracking-tight text-gray-900 sm:text-6xl lg:col-span-2 xl:col-auto text-red">
              Meet Chef<br></br> Jason A. Vickers         
          </h1>
            <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
              <p id="about-content" className="text-lg leading-8 text-gray-600 italic">
              Ohomous Nut Annokinam 
              <br></br>(Painting Owl)
              <br></br>

              Hassanamisco Band, Nipmuc Nation
              <br></br>
              <br></br>

              </p>
              <p id="about-content">

              Born in his ancestral home, Worcester, Massachusetts, Jason comes from two big families; his mother’s Italian heritage as well as his father’s Indigenous roots, based right there on the literal ground they lived on.
              <br></br>
              <br></br>


              The family moved to Seattle where Jason came of age in and around Seattle’s ever-growing restaurant scene around Pike Place Market. He saw the world of the farmer, the forager and the fisherman come alive every day on his way to school.
              <br></br>
              <br></br>


              As soon as he was able, Jason dove into this world, working around many of downtown Seattle’s most notable and award-winning hotels and restaurants.
              <br></br>
              <br></br>


              After years of studying, cooking, serving,  teaching and writing about cuisines of the world, Jason realized he was able to interact in this way with foods from everywhere but his own ancestral homelands. 
              <br></br>
              <br></br>


              With that thought in mind, he’s spent recent years applying his skill and ability to the incredible bounty of traditional foods that Native Country has to offer. This path has been carefully chosen and guided by Aunties, Generous Uncles, Elders and lots of wonderful and supportive relatives happy to see the old foods and some of the old ways (as well as new ways) brought to life through this work.
              <br></br>
              <br></br>


              Jason believes it is as much a pleasure as it is an honor to share these
              foods and ways in our communities today.

              <br></br>
              <br></br>

              NETONCKS METSU (FEEDING MY COUSINS) Is both the name of Jason’s business and the very heart of his mission.

              </p>
            </div>
            <img
              src={jason}
              alt="Photo of Jason A. Vickers"
              className="mt-10 w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2 xl:mt-36"
            />
          </div>
        </div>
        <div className="absolute inset-x-0 bottom-0 -z-10 h-24 bg-gradient-to-t from-white sm:h-32" />
      </div>

</>
    )
}

